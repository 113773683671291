import { graphql, useStaticQuery } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import Global from '../styles/global'
import BannerAnnounce from './banner-announce'
import Footer from './footer'
import Header from './header'
import SEO from './seo'

gsap.registerPlugin(ScrollTrigger)

const useSiteComponents = () => {
  const { site, datoCmsSite, shared } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
        globalSeo {
          siteName
          titleSuffix
          twitterAccount
          facebookPageUrl
          fallbackSeo {
            title
            description
            image {
              url
            }
            twitterCard
          }
        }
      }
      shared: datoCmsNewVnShared(locale: { eq: "vi" }) {
        logo {
          url
          format
        }
        logoAlternate {
          url
          format
        }
        topNav {
          singular
          label
          link {
            label
            link
          }
        }
        mainNav {
          singular
          label
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          link {
            label
            link
          }
          featured {
            slug
            headline
            date
            contentNode {
              childMarkdownRemark {
                excerpt
              }
            }
          }
          navigation {
            label
            link
            subLinks {
              label
              link
              description
              subLinks {
                label
                link
              }
            }
          }
        }
        visitGlobal
        globalSiteLabel
        globalSiteLink {
          link
          label
        }
        customerLabel
        customerArea {
          label
          link
          subLinks {
            label
            link
            image {
              format
              url
            }
          }
        }
        requestDemo {
          link
          label
        }
        marketSelector {
          singular
          label
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          link {
            label
            link
          }
          featured {
            slug
            headline
            date
            contentNode {
              childMarkdownRemark {
                excerpt
              }
            }
          }
          navigation {
            label
            link
            subLinks {
              label
              link
              description
              subLinks {
                label
                link
              }
            }
            image {
              url
              format
            }
          }
        }
        footerLogo {
          url
          alt
          title
          width
          height
        }
        footerLinks {
          link
          label
          subLinks {
            link
            label
          }
        }
        socialMediaLabel
        socialMedia {
          title
          image {
            url
            alt
            title
            width
            height
          }
          accountLink {
            link
            label
          }
        }
        legalLabel
        legalLinks {
          link
          label
        }
        copyright
        isActive
        bannerIcon {
          format
          url
        }
        bannerDescriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return { site, datoCmsSite, shared }
}

const setViewportWidth = () => {
  const vw = document.documentElement.clientWidth / 100
  document.documentElement.style.setProperty('--vw', `${vw}px`)
}

const Layout = ({
  pageTags,
  noIndex,
  headerTransition,
  headerTransitionColor,
  withNav,
  children,
}) => {
  const siteComponents = useSiteComponents()
  useEffect(() => {
    setViewportWidth()
    const onResize = debounce(setViewportWidth, 50)
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize, { passive: true })
    }
    return () => window.removeEventListener('resize', onResize, { passive: true })
  }, [setViewportWidth])

  const [initialized, setInitialized] = useState(false)

  useEffect(() => setInitialized(true), [])

  return (
    <div css={[tw`relative z-0 flex flex-col min-h-screen`]}>
      <Global />
      <SEO
        pageTags={pageTags}
        faviconTags={get(siteComponents, 'datoCmsSite.faviconMetaTags.tags')}
        siteUrl={get(siteComponents, 'site.siteMetadata.siteUrl')}
        noIndex={noIndex}
      />
      {get(siteComponents, 'shared.isActive') && (
        <BannerAnnounce
          description={get(siteComponents, 'shared.bannerDescriptionNode')}
          icon={get(siteComponents, 'shared.bannerIcon')}
        />
      )}
      <Header
        logoWhiteAccent={get(siteComponents, 'shared.logo')?.url}
        logoCyanAccent={get(siteComponents, 'shared.logoAlternate')?.url}
        callToAction={get(siteComponents, 'shared.requestDemo[0]')}
        topNavItems={get(siteComponents, 'shared.topNav')}
        navItems={get(siteComponents, 'shared.mainNav')}
        socialMediaLabel={get(siteComponents, 'shared.socialMediaLabel')}
        socialMedia={get(siteComponents, 'shared.socialMedia')}
        customerLabel={get(siteComponents, 'shared.customerLabel')}
        customerArea={get(siteComponents, 'shared.customerArea')}
        marketSelector={get(siteComponents, 'shared.marketSelector')}
        copyright={get(siteComponents, 'shared.copyright')}
        headerTransition={headerTransition}
        headerTransitionColor={headerTransitionColor}
        withNav={withNav}
      />
      <div
        css={[
          tw`flex-auto transition-opacity duration-300 bg-white pt-18 lg:pt-20`,
          !initialized && tw`opacity-0`,
        ]}
      >
        {children}
      </div>
      <Footer
        logo={get(siteComponents, 'shared.footerLogo')}
        linkGroups={get(siteComponents, 'shared.footerLinks')}
        globalSiteLabel={get(siteComponents, 'shared.globalSiteLabel')}
        globalSite={get(siteComponents, 'shared.globalSiteLink')}
        socialMediaLabel={get(siteComponents, 'shared.socialMediaLabel')}
        socialMedia={get(siteComponents, 'shared.socialMedia')}
        legalLabel={get(siteComponents, 'shared.legalLabel')}
        legal={get(siteComponents, 'shared.legalLinks')}
        copyright={get(siteComponents, 'shared.copyright')}
      />
    </div>
  )
}

Layout.defaultProps = {
  noIndex: false,
  headerTransition: false,
  headerTransitionColor: 'navy',
}

Layout.propTypes = {
  pageTags: PropTypes.object,
  noIndex: PropTypes.bool,
  headerTransition: PropTypes.bool,
  headerTransitionColor: PropTypes.oneOf(['white', 'navy']),
  children: PropTypes.node.isRequired,
}

export default Layout
